import './Footer.css';
import React from "react";
import instagram from '../assets/instagram.png'
import tiktok from '../assets/tiktok.png'
import mail from '../assets/mail.png'

function Footer() {

    const links = [
    {
        route: "https://www.instagram.com/instantnoodlefactory/",
        icon: instagram,
        label: "Instagram"
    },  {
        route: "https://www.tiktok.com/@instantnoodlefactory",
        icon: tiktok,
        label: "TikTok"
    }, {
        route: "mailto:inoodlefactory@gmail.com",
        icon: mail,
        label: "Mail"
    }]

    return (
        <div class="footer">
            <p class="questions"> questions? text us at (914) 510-2407 </p>
            <div class="IconRow">
                {links.map((link) => (
                    <a href={link.route} target="_blank" rel="noreferrer">
                        <img class="Icon" src={link.icon} alt={link.label}></img>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default Footer;