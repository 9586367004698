import './Links.css';
import React from "react";
import suzylogo from '../assets/suzylogo.svg'

function Links(props) {

    return (
        <div className="links">
            <img className="suzyLogo" src={suzylogo} alt="Instant Noodle Factory"></img>
            <div className="linkHrefButton">
                {props.links.map((link, index) => (
                    <a href={link["link"]} target="_blank" rel="noreferrer" class="order-href">
                        <p class="linkIndividual"> {link["text"]} </p>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default Links;