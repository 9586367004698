import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LICLinks from './pages/links/LICLinks';
import INFLinks from './pages/links/INFLinks';
import FormSuccess from './pages/email/FormSuccess';
import EmailSignUp from './pages/email/EmailSignUp';
import PrivacyPolicy from './pages/PrivacyPolicy'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
       <Routes>
        <Route path="/" element={ <App/> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicy/> } />
        <Route path="/liclinks" element={ <LICLinks/> } />
        <Route path="/links" element={ <INFLinks/> } />
        <Route path="/formsuccess" element={ <FormSuccess/> } />
        <Route path="/emailsignup/:location" action={({ params }) => {}} element={ <EmailSignUp />}/>
      </Routes>
      </BrowserRouter>,
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
