import './App.css';
import React, { Component } from "react";
import welcome from "./assets/welcome.svg"
import Footer from "./pages/Footer.js"
import ClosingMessage from "./pages/ClosingMessage.js"
import Carousel from "./pages/Carousel.js"
import Slideshow from "./pages/Slideshow.js"
import EmailForm from "./pages/email/EmailForm.js"

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    const appClass = isMobile ? 'AppMobile' : 'App';
    const logoClass = isMobile ? 'Logo' : 'LogoFull';
    // const brunchStarClass = isMobile ? ''

    let orderDiv = 
    <div className={isMobile ? "OrderRow" : "OrderRow OrderRowFlex"}>
        <div className={isMobile ? "OrderButtonMobile" : "OrderButton"}>
          <a href={"https://inoodlefactoryapp.com/landing"} rel="noreferrer" class="order-href">
            <p class="orderText"> Download Noodlepedia </p>
          </a>
        </div>
        <div className={isMobile ? "OrderButtonMobile" : "OrderButton"}>
          <a href={"https://shop-inoodlefactory.com/"} rel="noreferrer" class="order-href">
            <p class="orderText"> Subscription Boxes </p>
          </a>
        </div>
      </div>

    return (
      <div className={appClass}>
        <img className={logoClass} src={welcome} alt="welcome to instant noodle factory"></img>
        <Slideshow/>
        <Carousel isMobile = {isMobile}></Carousel>
        <ClosingMessage />
        {orderDiv}
        <EmailForm isMobile = {isMobile}></EmailForm>
        <Footer></Footer>
      </div>
      );
    }
  }


export default App;
