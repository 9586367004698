import React from "react";

function PrivacyPolicy() {

    return (
        <div className="privacyPolicy">
            <p> 
                privacy policy
            </p>
        </div>
    )
}

export default PrivacyPolicy;