import './LICLinks.css';
import React, { Component } from "react";
import Links from '../../assets/Links.js'

class INFLinks extends Component {
    constructor () {
        super()
        this.INFLinks = [
            {"text": "Check out Noodlepedia", "link": "https://inoodlefactoryapp.com/landing"}, 
            {"text": "Check out Noodle Club", "link": "https://shop-inoodlefactory.com/"},
            {"text": "Visit our Homepage", "link": "https://inoodlefactory.com/"},
        ]
    }
    
    render() {
        return (
            <div>
                <Links links = {this.INFLinks} > </Links>
            </div>
        )
    }
}
export default INFLinks;