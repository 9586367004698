import './ClosingMessage.css';
import React from "react";
import founders from '../assets/images/founders.png'
import closingText from '../assets/images/closingText.png'
import suzyLogo from '../assets/images/suzyLogo.svg'

function ClosingMessage(props) {
    return (
        <div class="">
            <img className="closingTextImage" src={closingText} alt="curvy text saying we're closing!"/>
            <div className="flex">
                <img className="foundersImage" src={founders} alt="founders tat and cierra in front of the welcome to instant noodle factory sign"/>
                <p className="thankYouText"> Thanks for your support! Working on Instant Noodle Factory for these past 2 years has been an incredible journey filled with so many unexpected twists and turns. We are so happy that we got to share our love of instant noodles with New York City, and everyone who traveled to visit our small shops!</p>
            </div>
            <img className="suzyLogoImage" src={suzyLogo} alt="our mascot suzy slurping noodles"/>
            <p className="closingBodyText">While the restaurants will be closing, you can still share your love of instant noodles with us through our new mobile app, Noodlepedia, and through our new noodle subscription boxes - shipping nationwide across the US! </p>
        </div>
    )
}

export default ClosingMessage;